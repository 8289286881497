import React from 'react';
import { Card, Table } from 'react-bootstrap';
import style from '../../Styles/ProjectTrackingModel.module.css';
const ProjectTrackingmodelcomp = (props) => {
  const { toggleModal, value } = props;

  return (
    <>
      <div>
        <div>        <Table responsive>
          <thead>
            <tr>
              <th>S.No</th> <th>Project Name</th> <th>Total Hours</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(value).map(
              (item, index) =>
                item !== 'username' && (
                  <tr>
                    <td key={index}>{index}</td>
                    <td key={index}>{item}</td>
                    <td key={index}>{value[item]}</td>
                  </tr>
                ),
            )}
          </tbody>
        </Table>
      </div>
      </div>
      {/* <div>
        <div className="row">
          {Object.keys(value).map(
            (item, index) =>
              item !== 'username' && (
                <div className="col-6">
                  <Card>
                    <div className="px-2 ">
                      <div>
                        Project Name : <span>{item}</span>
                      </div>
                      <div>
                        Total Hours : <span>{value[item]}</span>
                      </div>
                    </div>
                  </Card>{' '}
                </div>
              ),
          )}
        </div>
      </div> */}
    </>
  );
};

export default ProjectTrackingmodelcomp;
