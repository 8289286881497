import React, { useEffect, useState } from 'react';
import { Row } from 'reactstrap';
import DashColorCards from './DashColorCards';
import MonthReport from './MonthReport';
import EmployeeList from './EmployeeList';
import style from '../../Styles/Dasbboard.module.css';
import { useDispatch } from 'react-redux';
import { MONTHS, NORECORD } from '../../constant/constant';
import { Select } from 'antd';
import {
  setColumn,
  setFilterMonth,
  setFilterYear,
} from '../../store/reducers/filterState';
import {
  generateCurrentMonth,
  generateCurrentYear,
  generateYears,
} from '../../ReusableFn/reusableFn';
import DashboardFilters from './DashboardFilters';
import EmployeeReport from './EmployeeReport';
import DashboardApi from '../../Api/DashboardApi';
import { useAuth } from '../../Context/AuthContext';
import { toastError } from '../Toast/ToastComponent';
import LockedList from './LockedList';
import useFilterStateData from '../../customHooks/useFilterStateData';

const DashboardComp = () => {
  const [change, setChange] = useState('');
  const { authState } = useAuth();
  const dispatch = useDispatch();
  const [dashData, setDashData] = useState({
    overallStatus: [],
    projectReport: [],
    leaveTaken: [],
    permissionTaken: [],
    lockedCount: [],
  });
  const { month: filterStateMonth, year: filterStateYear } =
    useFilterStateData();
  let filterArr = {
    month: filterStateMonth,
    year: filterStateYear,
  };
  const filterArray = [
    {
      options: MONTHS,
      optionFilterProp: 'label',
      placeholder: 'Month',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: filterStateMonth,
      controlName: 'month',
      allowClear: false,
      size: '',
      input: 'select',
    },
    {
      options: generateYears() || [],
      optionFilterProp: 'label',
      placeholder: 'Year',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: filterStateYear,
      controlName: 'year',
      allowClear: false,
      size: '',
      input: 'select',
    },
  ];

  const setFilterLocal = (data) => {
    let getFilterLocal = sessionStorage.getItem('filterState');
    let parsed =
      getFilterLocal == undefined
        ? { month: '', year: '' }
        : JSON.parse(atob(getFilterLocal));
    sessionStorage.setItem(
      'filterState',
      btoa(JSON.stringify({ ...parsed, ...data })),
    );
  };
  const handleFilterChange = (e, controlName, type) => {
    filterArr = { ...filterArr, [controlName]: e };

    dispatch(setColumn([]));

    if (!!filterArr['month']) {
      setFilterLocal({ month: filterArr['month'] });
      dispatch(setFilterMonth(filterArr['month']));
    }
    if (!!filterArr['year']) {
      setFilterLocal({ year: filterArr['year'] });
      dispatch(setFilterYear(filterArr['year']));
    }

    getDashData();
  };

  const getDashData = async () => {
    const reqData = {
      ...filterArr,
      userRoleID: authState?.userRoleID,
      id: authState?.id,
    };

    try {
      const [
        overallStatus,
        projectReport,
        leaveTaken,
        permissionTaken,
        lockedCount,
      ] = await Promise.all([
        DashboardApi.overAllReport(reqData),
        DashboardApi.userProjectReport(reqData),
        DashboardApi.leaveTakenReport(reqData),
        DashboardApi.permissionTakenReport(reqData),
        DashboardApi.lockedCount(reqData),
      ]);

      const dashData = {
        overallStatus: overallStatus?.data?.result,
        projectReport: projectReport?.data?.result,
        leaveTaken: leaveTaken?.data?.result,
        permissionTaken: permissionTaken?.data?.result,
        lockedCount: lockedCount?.data?.result,
      };

      setDashData(dashData);
    } catch (err) {
      toastError(err.message);
    }
  };

  useEffect(() => {
    getDashData();
    return () => {
      filterArr = {
        month: filterStateMonth,
        year: filterStateYear,
      };
    };
  }, []);

  return (
    <section className={`${style.dashboard}`}>
      <div className="d-flex px-1 px-lg-0  justify-content-between justify-content-lg-end ">
        <DashboardFilters
          filterArray={filterArray}
          handleFilterChange={handleFilterChange}
        />
      </div>
      <div>
        <div className="row m-0 p-0 mt-1 mt-lg-0  ">
          <div className={` col-lg-8 px-0 ${style.firstdiv}`}>
            <div className="row m-0 p-0 h-100 ">
              <div
                className={` col-lg-4 px-0 pe-lg-1 ${style.DashColorCardshi}`}
              >
                <div
                  className={`row m-0 p-0 h-100 d-flex ${style.dashCardCol}`}
                >
                  <DashColorCards projectReport={dashData.projectReport[0]} />
                </div>
              </div>
              <div className={` col-lg-8  px-0 pb-2 ${style.DashColorCardshi}`}>
                <MonthReport overallStatus={dashData.overallStatus[0]} />
              </div>
              {/* <div className="col-12 px-0 mt-2 px-1 " style={{ height: '64%' }}>
                <DepartmentChart />
              </div> */}
              <div
                className={` col-12 px-0 d-flex  ${style.EmployeeReportshi}`}
              >
                <div className="col-12 pt-1 ">
                  <EmployeeReport
                    filterArr={{ ...filterArr }}
                    permissionTaken={dashData.permissionTaken}
                    leaveTaken={dashData.leaveTaken}
                    lockedCount={dashData.lockedCount}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            // className="col-lg-4 col-12 px-0 pt-1 ps-1 "
            className={` col-lg-4  px-0 pt-1 ps-1 ${style.EmployeeListshi}`}
            // style={{ minHeight: 482, height: 482 }}
          >
            <EmployeeList filterArr={{ ...filterArr }} />
          </div>
          {/* <div className="col-12 px-0 mt-3 ">
            <EmployeeReport
              permissionTaken={dashData.permissionTaken[0]}
              leaveTaken={dashData.leaveTaken[0]}
              lockedCount={dashData.lockedCount}
            />
          </div> */}
          <div className="col-lg-12 mt-lg-0 mt-2 px-0 ">
            <LockedList lockedCount={dashData.lockedCount} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardComp;

// const getDashData = async () => {
//   let reqData = {
//     ...filterArr,
//     userRoleID: authState?.userRoleID,
//     id: authState?.id,
//   };
//   try {
//     const response = [
//       await DashboardApi.overAllReport(reqData),
//       await DashboardApi.userProjectReport(reqData),
//       await DashboardApi.leaveTakenReport(reqData),
//       await DashboardApi.permissionTakenReport(reqData),
//       await DashboardApi.lockedCount(reqData),
//     ];
//     Promise.all(response)
//       .then((resData) => {
//         const filteredResData = resData.map((item) => item?.data?.result);
//         let obj = {};
//         obj['overallStatus'] = filteredResData[0];
//         obj['projectReport'] = filteredResData[1];
//         obj['leaveTaken'] = filteredResData[2];
//         obj['permissionTaken'] = filteredResData[3];
//         obj['lockedCount'] = filteredResData[4];
//         setDashData({ ...obj });
//       })
//       .catch((err) => {
//         toastError(err.message);
//         console.log(err);
//       });
//   } catch (err) {
//     toastError(err.message);
//     console.log(err);
//   }
// };
