import React, { useEffect, useRef, useState } from 'react';
import { Input } from 'antd';

import DepartmentReportTable from './DepartmentReportTable';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../Context/AuthContext';
import Department from '../../../Api/Department';
import { toastError } from '../../Toast/ToastComponent';

import Loader from '../../ReusableComponents/Loader';
import TmFilter from '../../ReusableComponents/TmFilter';
import {
  dayjsToDateFormat,
  generateCurrentMonth,
  generateCurrentYear,
  generateYears,
} from '../../../ReusableFn/reusableFn';
import { MONTHS } from '../../../constant/constant';
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { getDepartmentReport } from '../../../store/reducers/actionPermission';
import useFilterStateData from '../../../customHooks/useFilterStateData';

const columns = [
  { field: 'serialNo', headerName: 'S.NO', className: 'col-auto' },
  { field: 'department_name', headerName: 'DEPARTMENT', className: 'col-2' },
  {
    field: 'department_head',
    headerName: 'DEPARTMENT HEAD',
    className: 'col-2',
  },
  { field: 'no_of_projects', headerName: 'NO OF PROJECTS', className: 'col-2' },
  { field: 'no_of_users', headerName: 'NO OF USERS', className: 'col-2' },
  { field: 'pendingCount', headerName: 'PENDING COUNT', className: 'col-2' },
  { field: 'approveCount', headerName: 'APPROVED COUNT', className: 'col-2' },
  // { field: 'action', headerName: 'ACTION', className: 'col-1' },
];

const DepartmentReport = () => {
  const { month: filterStateMonth, year: filterStateYear } =
    useFilterStateData();
  let filterInitialVal = {
    month: filterStateMonth,
    year: filterStateYear,
  };

  const filterArray = [
    {
      options: MONTHS,
      optionFilterProp: 'label',
      placeholder: 'Month',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: filterStateMonth,
      controlName: 'month',
      allowClear: false,
      input: 'select',
    },
    {
      options: generateYears() || [],
      optionFilterProp: 'label',
      placeholder: 'Year',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: filterStateYear,
      controlName: 'year',
      allowClear: false,
      input: 'select',
    },
  ];

  const [loading, setLoading] = useState(false);
  const debounceTimeoutRef = useRef(null);
  const [filterArr, setFilterArr] = useState({ search: '' });
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { Search } = Input;

  const dataum = useSelector(getDepartmentReport);

  const handleButtonPermission = () => {
    if (!dataum?.checked) {
      navigate('/');
    }
  };

  const { authState } = useAuth();

  const getReportData = async (obj) => {
    let reqData = {
      ...obj,
      ...filterInitialVal,
      id: authState?.id,
      userRoleID: authState?.userRoleID,
    };

    try {
      const result = await Department.report(reqData);

      if (result?.data?.success) {
        setData(result?.data?.result);

        setLoading(true);
      } else {
        toastError(result?.data?.message);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  const handleclick = (row, type) => {
    if (type == 'view') {
      navigate(
        `/department_view/${btoa(row?.id)}/${btoa(row?.department_name)}`,
      );
    }
  };

  const handleSearch = (e) => {
    let value = e.target.value.trim();

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      getReportData({ search: value });
    }, 400);
    setFilterArr((pre) => ({ ...pre, search: value }));
  };

  const handleFilterChange = (obj, type) => {
    if (type === 'date') {
      obj['date_of_joining'] = obj['date_of_joining']
        ? dayjsToDateFormat(obj['date_of_joining'])
        : '';
    }
    filterInitialVal = { ...filterInitialVal, ...obj } || {};
    getReportData();
  };
  useEffect(() => {
    getReportData(filterArr);
    if (dataum) {
      handleButtonPermission();
    }
  }, [dataum]);
  return (
    <div>
      <div className="d-md-flex ">
        <div>
          {' '}
          <h5 className="text-nowrap">Department Report</h5>
        </div>
        <div className="ms-md-auto">
          <Search allowClear onChange={handleSearch} placeholder="Search " />
        </div>
      </div>

      <div className="mt-2 mb-0"></div>
      {loading && (
        <div>
          <Row>
            <Col>
              <div>
                <TmFilter
                  filterArray={filterArray}
                  handleFilterChange={handleFilterChange}
                />
              </div>
            </Col>
            <Col md={12}>
              <div className="">
                <DepartmentReportTable
                  handleclick={handleclick}
                  columns={columns}
                  rows={data}
                />
              </div>
            </Col>
          </Row>
        </div>
      )}

      {!loading && <Loader />}
    </div>
  );
};

export default DepartmentReport;
