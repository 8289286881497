import { configureStore } from '@reduxjs/toolkit';
import permissionReducer from './reducers/actionPermission.js';
import filterStateReducer from './reducers/filterState.js';

const store = configureStore({
  reducer: {
    permission: permissionReducer,
    filterState: filterStateReducer,
  },
});

export default store;
