import React, { useEffect, useRef, useState } from 'react';
import { Input } from 'antd';
import { useParams } from 'react-router-dom';

import {
  DAY_TYPES,
  MODAL_HEIGHT,
  MONTHS,
  USERTIMESHEET_STATUS,
} from '../../../constant/constant';
import { useNavigate } from 'react-router-dom';
import style from '../../../Styles/UserManagementView.module.css';

import {
  generateCurrentMonth,
  generateCurrentYear,
  generateYears,
} from '../../../ReusableFn/reusableFn';
import { FaArrowLeft } from 'react-icons/fa';
import {
  toastError,
  toastSuccess,
  toastWarn,
} from '../../Toast/ToastComponent';
import TmFilter from '../../ReusableComponents/TmFilter';
import Loader from '../../ReusableComponents/Loader';
import Department from '../../../Api/Department';
import UserReportTable from './UserReportTable';
import AntdModalComp from '../../ReusableComponents/AntdModalComp';
import ViewActivity from './ViewActivity';
import { useAuth } from '../../../Context/AuthContext';
import useFilterStateData from '../../../customHooks/useFilterStateData';

let columns = [
  // { field: 'serialNo', headerName: 'S.NO', className: 'col-auto' },
  { field: 'username', headerName: 'USERS', className: 'col-3' },
  { field: 'department_name', headerName: 'DEPARTMENT', className: 'col-3' },
  { field: 'designation_name', headerName: 'DESIGNATION', className: 'col-3' },

  { field: 'projects', headerName: ' PROJECTS', className: 'col-3' },
  { field: 'leaveDays', headerName: 'LEAVE DAYS', className: 'col-2' },
  {
    field: 'permissionDays',
    headerName: 'PERMISSION DAYS',
    className: 'col-1',
  },
  { field: 'pendingDays', headerName: 'PENDING DAYS', className: 'col-1' },
  { field: 'approvedDays', headerName: 'APPROVED DAYS', className: 'col-1' },
  { field: 'rejectedDays', headerName: 'REJECTED DAYS', className: 'col-1' },

  { field: 'missingDays', headerName: 'MISSING DAYS', className: 'col-1' },
  { field: 'lockedDays', headerName: 'LOCKED DAYS', className: 'col-1' },
  {
    field: 'firstApproverMonth',
    headerName: '1ST APPROVER STATUS',
    className: 'text-nowrap',
  },
  {
    field: 'secondApproverMonth',
    headerName: '2ND APPROVER STATUS',
    className: 'text-nowrap',
  },
  {
    field: 'monthTimeSheet',
    headerName: 'MONTH STATUS',
    className: 'col-auto',
  },
  {
    field: 'activity',
    headerName: 'HISTORY',
    className: 'col-auto',
  },
];

let modalHeader = <>History</>;
const DepartReportView = () => {
  const [loading, setLoading] = useState(false);
  const [viewData, setViewData] = useState({
    missedArray: [],
    lockedArray: [],
  });
  const { month: filterStateMonth, year: filterStateYear } =
    useFilterStateData();
  let filterValue = {
    status: null,
    month: filterStateMonth,
    year: filterStateYear,
    search: null,
    dayType: '',
  };
  let filterArray = [
    {
      options: MONTHS,
      optionFilterProp: 'label',
      placeholder: 'Month',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: filterStateMonth,
      controlName: 'month',
      allowClear: false,
      input: 'select',
    },
    {
      options: generateYears() || [],
      optionFilterProp: 'label',
      placeholder: 'Year',
      order: 1,
      col: 1,
      mode: '',
      maxTagCount: '',
      defaultValue: filterStateYear,
      controlName: 'year',
      allowClear: false,
      input: 'select',
    },
    {
      options: DAY_TYPES,
      optionFilterProp: 'label',
      placeholder: 'Day Types',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: '',
      controlName: 'dayType',
      //allowClear: true,
      input: 'select',
    },
    {
      options: USERTIMESHEET_STATUS,
      optionFilterProp: 'label',
      placeholder: 'First Approver',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: null,
      controlName: 'firstApproverStatus',
      allowClear: true,
      input: 'select',
    },
    {
      options: USERTIMESHEET_STATUS,
      optionFilterProp: 'label',
      placeholder: 'Second Approver',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: null,
      controlName: 'secondApproverStatus',
      allowClear: true,
      input: 'select',
    },

    {
      options: USERTIMESHEET_STATUS,
      optionFilterProp: 'label',
      placeholder: 'Month Status',
      order: 1,
      col: 2,
      mode: '',
      maxTagCount: '',
      defaultValue: null,
      controlName: 'status',
      allowClear: true,
      input: 'select',
    },
  ];

  const debounceTimeoutRef = useRef(null);
  const [modal, SetModal] = useState(false);
  const navigate = useNavigate();
  const { authState } = useAuth();
  const [data, setData] = useState([]);
  const { Search } = Input;
  const { department_name, department_id } = useParams();

  useEffect(() => {
    getReportList(filterValue);

    return () => {
      filterValue = {
        status: null,
        month: filterStateMonth,
        year: filterStateYear,
        search: null,
        dayType: '',
      };
    };
  }, []);

  const getReportList = async (filterValue) => {
    let reqData = {
      ...filterValue,
      user_id: authState?.id,
      userRoleID: authState?.userRoleID,
    };

    if (department_id) {
      reqData.departmentID = atob(department_id);
    }

    try {
      const result = await Department.report2(reqData);

      if (result?.data?.success) {
        setData(result?.data?.result || []);

        setLoading(true);
      } else {
        toastError(result?.data?.message);
      }
    } catch (error) {
      toastError(error.response.data.message);
    }
  };

  const handleFilterChange = (obj, type) => {
    filterValue = { ...filterValue, ...obj } || {};
    getReportList(filterValue);
  };
  const handleSearch = (e) => {
    let value = e.target.value.trim();

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      handleFilterChange({ ...filterValue, search: value });
    }, 400);
  };

  const handleClick = (item, type) => {
    getActivityData(item);
  };
  const getActivityData = async (item) => {
    modalHeader = <>{item.username}'s History</>;
    const reqData = {
      user_id: item.id,
      monthName: filterValue.month,
      year: filterValue.year,
    };
    try {
      const response = await Department.viewActivity(reqData);
      if (response.data.success) {
        setViewData((pre) => ({ ...pre, ...response.data.result }));
      } else {
        toastWarn(response.data.message);
      }
    } catch (error) {
      toastError('Failed to create user');
    }
    toggleModal();
  };
  const toggleModal = () => {
    SetModal(!modal);
  };

  return (
    <div>
      <div className="d-md-flex ">
        <div className="d-flex">
          <div>
            {department_name && (
              <span
                className={`${style['arrow-icon']} border px-2 pb-1`}
                onClick={() => navigate('/department_report')}
              >
                <FaArrowLeft />
              </span>
            )}
          </div>
          <div>
            <h5 className="text-nowrap ps-1">
              {department_name ? atob(department_name) : 'User Report'}
            </h5>
          </div>
        </div>
        <div className="ms-md-auto">
          <Search allowClear onChange={handleSearch} placeholder="Search " />
        </div>
      </div>

      <div className="mt-2 mb-0">
        <TmFilter
          filterArray={filterArray}
          handleFilterChange={handleFilterChange}
        />
      </div>
      {loading && (
        <div>
          <div className="">
            <UserReportTable
              columns={columns}
              rows={data}
              handleClick={handleClick}
            />
          </div>
        </div>
      )}
      {!loading && <Loader />}

      <span>
        <AntdModalComp
          width="40%"
          toggleModal={toggleModal}
          modalOpen={modal}
          modalHeader={modalHeader}
          height={MODAL_HEIGHT.auto}
          modalBody={<ViewActivity viewData={viewData} />}
        />
      </span>
    </div>
  );
};

export default DepartReportView;
