import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import dayjs from 'dayjs';

export const downloadExcel = async (downloadData) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(`${downloadData.sheetName}`);
  worksheet.getRow(1).height = 30;

  // Set headers with specific widths
  worksheet.columns = [
    {
      header: 'S.No',
      key: 'sno',
      width: 10,
    },
    {
      header: 'Date',
      key: 'date',
      width: 20,
    },
    {
      header: 'Project',
      key: 'projectName',
      width: 20,
    },
    {
      header: 'Module',
      key: 'module',
      width: 20,
    },
    {
      header: 'Tasks',
      key: 'task',
      width: 50,
    },
    {
      header: 'Time(From - To)',
      key: 'from',
      width: 15,
    },
    {
      header: 'To',
      key: 'to',
      width: 15,
    },
    {
      header: 'Total Hours Worked',
      key: 'hours',
      width: 15,
    },
    {
      header: 'Remarks',
      key: 'totalHours',
      width: 30,
    },
  ];

  const emptyRow = worksheet.addRow({
    sno: '',
    date: '',
    projectName: '',
    module: '',
    task: '',
    from: '',
    to: '',
    hours: '',
    totalHours: '',
  });

  emptyRow.eachCell((cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'DDDDDD' }, // Gray color
    };

    cell.border = {
      top: { style: 'thin', color: { argb: '000000' } },
      left: { style: 'thin', color: { argb: '000000' } },
      bottom: { style: 'thin', color: { argb: '000000' } },
      right: { style: 'thin', color: { argb: '000000' } },
    };
  });
  worksheet.mergeCells(`F1:G1`);

  // Add data
  downloadData['data'].forEach((entry, idx) => {
    let mergeCellArr = [];
    entry.tasks.forEach((task, index, arr) => {
      const row = worksheet.addRow({
        sno: idx + 1,
        date: index === 0 ? entry.date : '',
        projectName: task.projectName || '',
        module: task.module || '',
        task: task.task || '',
        from: task?.from || '',
        to: task?.to || '',
        hours: task.hours,
        totalHours: index === 0 ? entry.totalHours : '',
      });

      mergeCellArr.push(row.number);
      if (entry.tasks.length - 1 === index) {
        worksheet.mergeCells(
          `A${mergeCellArr[0]}:A${mergeCellArr[mergeCellArr.length - 1]}`,
        );
        worksheet.mergeCells(
          `B${mergeCellArr[0]}:B${mergeCellArr[mergeCellArr.length - 1]}`,
        );
        worksheet.mergeCells(
          `I${mergeCellArr[0]}:I${mergeCellArr[mergeCellArr.length - 1]}`,
        );
      }
      row.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin', color: { argb: '000000' } },
          left: { style: 'thin', color: { argb: '000000' } },
          bottom: { style: 'thin', color: { argb: '000000' } },
          right: { style: 'thin', color: { argb: '000000' } },
        };
        let blockColors =
          cell?._column?._number !== 1 &&
          cell?._column?._number !== 9 &&
          cell?._column?._number !== 2;
        if (task.type === 'Permission' && blockColors) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFffeb9c' },
          };
          cell.font = {
            name: 'Times New Roman',
            size: 12,
            color: { argb: 'FF9c6500' },
          };
          cell.alignment = {
            horizontal: 'center',
            vertical: 'middle',
          };
        } else if (task.type === 'weekend' || task.type === 'publicHoliday') {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFc6efce' },
          };
          cell.font = {
            name: 'Times New Roman',
            size: 12,
            color: { argb: 'FF006120' },
          };
          cell.alignment = {
            horizontal: 'center',
            vertical: 'middle',
          };
        } else if (task.type === 'leave') {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFffc7ce' },
          };
          cell.font = {
            name: 'Times New Roman',
            size: 12,
            color: { argb: 'FF9c0006' },
          };
          cell.alignment = {
            horizontal: 'center',
            vertical: 'middle',
          };
        } else {
          cell.font = {
            name: 'Times New Roman',
            size: 12,
            color: { argb: 'FF000500' },
          };
          if (cell?._column?._number === 5) {
            cell.alignment = {
              horizontal: 'left',
              vertical: 'middle',
            };
          } else {
            cell.alignment = {
              horizontal: 'center',
              vertical: 'middle',
            };
          }
        }
        // else if(task.type === ''){}
      });
    });
    const emptyRow = worksheet.addRow({
      sno: '',
      date: '',
      projectName: '',
      module: '',
      task: '',
      from: '',
      to: '',
      hours: '',
      totalHours: '',
    });
    emptyRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDDDDD' }, // Gray color
      };

      cell.border = {
        top: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } },
      };
    });
  });

  worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
    if (rowNumber !== 1) {
      row.eachCell((cell) => {
        // console.log("cell",cell?._column?._number === 5 );
        // if (cell?._column?._number === 5) {
        //   cell.alignment = {
        //     horizontal: 'left',
        //     vertical: 'middle',
        //   };
        // } else {
        //   cell.alignment = {
        //     horizontal: 'center',
        //     vertical: 'middle',
        //   };
        // }
      });
    } else {
      row.eachCell((cell) => {
        cell.fill = {
          type: 'pattern',
          font: {
            name: 'Times New Roman',
            size: 20,
          },
          pattern: 'solid',
          fgColor: { argb: 'FFffc000' },
        };
        cell.font = {
          name: 'Times New Roman',
          size: 12,
        };
        cell.alignment = {
          horizontal: 'center',
          vertical: 'middle',
        };
        cell.border = {
          top: { style: 'thin', color: { argb: '000000' } },
          left: { style: 'thin', color: { argb: '000000' } },
          bottom: { style: 'thin', color: { argb: '000000' } },
          right: { style: 'thin', color: { argb: '000000' } },
        };
        cell.font = { bold: true, size: 12 };
      });
    }
  });

  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), `${downloadData.fileName}`);
};

export const attendanceReport = async (downloadData) => {
  const convertDateFormat = (date) =>
    dayjs(date, 'DD-MM-YYYY').format('DD-MMM-YYYY');
  const excelHeaders1 = [
    { header: 'S. NO', key: 'serialNumber', width: 10 },
    { header: 'ASS. ID', key: 'employeeCode', width: 15 },
    { header: 'NAME', key: 'username', width: 30 },
    { header: 'DOJ', key: 'date_of_joining', width: 18 },
    { header: 'DESIGNATION', key: 'department_name', width: 30 },
    { header: 'TEAM', key: 'designation_name', width: 30 },
  ];

  const excelHeaders2 = [];
  let datee = `${downloadData.year}-${downloadData.month}-01`;
  const excelHeadr = dayjs(datee).format('MMM-YYYY');
  const generateColumn = () => {
    const daysInMonth = dayjs(datee).daysInMonth();
    for (let i = 1; i <= daysInMonth; i++) {
      excelHeaders2.push({ header: `${i}`, key: `${i}`, width: 5 });
    }
  };
  generateColumn();

  const excelHeaders3 = [
    { header: 'PRESENT', key: 'presentDays', width: 25 },
    { header: 'WEEK OFF', key: 'weekOffDays', width: 25 },
    { header: 'PUBLIC HOLIDAYS', key: 'publicHolidays', width: 25 },
    { header: 'COMP OFF', key: 'compOffDays', width: 25 },
    { header: 'ON DUTY', key: 'onDutyDays', width: 25 },
    { header: 'EXTRA WORKING', key: 'extraWorkingHours', width: 25 },
    { header: 'MATERNITY LEAVE', key: 'maternityLeave', width: 25 },
    { header: 'PATERNITY LEAVE', key: 'paternityLeave', width: 25 },
    { header: 'MARRIAGE LEAVE', key: 'marriageLeave', width: 25 },
    { header: 'CASUAL LEAVE', key: 'casualLeave', width: 25 },
    { header: 'SICK LEAVE', key: 'sickLeave', width: 25 },
    { header: 'TOTAL', key: 'downloadTotalDays', width: 25 },
    { header: 'LOP', key: 'downloadLOPDays', width: 25 },
    { header: 'NMD', key: 'noMoment', width: 25 },
    { header: 'REMARKS', key: 'Remarks', width: 50 },
  ];
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(`${downloadData.sheetName}`);
  worksheet.columns = [...excelHeaders1, ...excelHeaders2, ...excelHeaders3];
  downloadData['data'].forEach(async (item) => {
    let daysObj = {};
    excelHeaders2.forEach((day) => {
      daysObj[day?.key] = item[day?.key] || '-';
    });
    const row = worksheet.addRow({
      serialNumber: item?.serialNumber,
      employeeCode: item?.employeeCode,
      username: item?.username,
      date_of_joining: convertDateFormat(item?.date_of_joining),
      department_name: item?.department_name,
      designation_name: item?.designation_name,
      ...daysObj,
      presentDays: item?.presentDays,
      weekOffDays: item?.weekOffDays,
      publicHolidays: item?.publicHolidays,
      compOffDays: item?.compOffDays,
      onDutyDays: item?.onDutyDays,
      extraWorkingHours: item?.extraWorkingHours,
      maternityLeave: item?.maternityLeave,
      paternityLeave: item?.paternityLeave,
      marriageLeave: item?.marriageLeave,
      casualLeave: item?.casualLeave,
      sickLeave: item?.sickLeave,
      downloadTotalDays: item?.downloadTotalDays,
      downloadLOPDays: item?.downloadLOPDays,
      noMoment: item?.noMoment || '-',
      Remarks: item?.Remarks || '-',
    });

    row.height = 30;
    row.eachCell((cell) => {
      //only colors and specific styles
      // console.log('cell', cell);
      if (
        cell?.value == 'SL' ||
        cell?.value == 'CL' ||
        cell?.value == 'ML' ||
        cell?.value == 'PL'
      ) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFffff00' },
        };
      }
      if (cell?.value == 'WO') {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF9dc3e6' },
        };
      }
    });
  });
  worksheet.insertRow(1, [`Staff Attendance ${excelHeadr}`]);
  worksheet.mergeCells(`A1:AX1`);
  worksheet.getRow(1).height = 30;
  worksheet.getRow(2).height = 30;
  worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
    row.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } },
      };
      cell.font = {
        name: 'Times New Roman',
        size: 11,
        bold: true,
      };
      cell.alignment = {
        horizontal: 'center',
        vertical: 'middle',
        wrapText: true,
      };
      if (
        (cell?._column?._key === 'username' ||
          cell?._column?._key === 'employeeCode' ||
          cell?._column?._key === 'designation_name' ||
          cell?._column?._key === 'department_name' ||
          cell?._column?._key === 'Remarks') &&
        cell.row != 1 &&
        cell.row != 2
      ) {
        cell.alignment = {
          horizontal: 'left',
          vertical: 'middle',
          wrapText: true,
        };
      }
      if (cell.row == 1) {
        cell.alignment = {
          horizontal: 'left',
          vertical: 'middle',
          wrapText: true,
        };
      }
      if (cell.row == 2) {
        cell.border = {
          top: { style: 'thin', color: { argb: '000000' } },
          left: { style: 'thin', color: { argb: '000000' } },
          bottom: { style: 'double', color: { argb: '000000' } },
          right: { style: 'thin', color: { argb: '000000' } },
        };
      }
    });
  });

  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), `${downloadData?.fileName}`);
};

export const projectTrackingReport = async (downloadData) => {
  const excelHeaders = [];
  const displayRowKeys = [];
  downloadData['columns'].forEach((item) => {
    excelHeaders.push({
      header: item?.headerName,
      key: item?.field,
      width: 30,
    });
    displayRowKeys.push(item?.field);
  });
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(`${downloadData.sheetName}`);
  worksheet.columns = [...excelHeaders];
  downloadData['rows'].forEach(async (item) => {
    const passingObj = {};
    displayRowKeys.forEach((itm) => (passingObj[itm] = item[itm] || '-'));
    const row = worksheet.addRow({
      ...passingObj,
    });
    // row.height = 30;
  });
  worksheet.insertRow(1, [``, 'PROJECTS']);
  // worksheet.mergeCells(`B1:X1`);
  worksheet.mergeCells(`A1:A2`);
  worksheet.getCell('A1').value = 'USER NAME';
  // worksheet.getRow(1).height = 30;
  worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
    row.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } },
      };
      cell.font = {
        name: 'Times New Roman',
        size: 11,
        // bold: true,
      };
      cell.alignment = {
        horizontal: 'center',
        vertical: 'middle',
        // wrapText: true,
      };

      if (cell.row == 1) {
        cell.alignment = {
          horizontal: 'left',
          vertical: 'middle',
          wrapText: true,
        };
        cell.font = {
          bold: true,
        };
      }
      if (cell.row == 2) {
        cell.border = {
          top: { style: 'thin', color: { argb: '000000' } },
          left: { style: 'thin', color: { argb: '000000' } },
          bottom: { style: 'thin', color: { argb: '000000' } },
          right: { style: 'thin', color: { argb: '000000' } },
        };
        cell.font = {
          bold: true,
        };
      }
    });
  });

  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), `${downloadData?.fileName}`);
};
